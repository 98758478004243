import {
  email,
  minLength,
  maxLength,
  required
} from 'vuelidate/lib/validators'

export const contactForm = {
  name: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(255)
  },
  email: {
    required,
    email,
    maxLength: maxLength(255)
  },
  message: {
    required,
    minLength: minLength(3)
  }
}
