<template lang="pug">
  div.contact
    div.contact__main
      form(@submit.prevent="handleSubmit").form
        h2.h2.form__title Contact Us
        div.text.form__text
          p Get in touch with us for assistance and inquiries.
        label(
          for="email"
          :class="$v.form.email.$error && 'invalid'"
        ).form__label
          input.input(
            type="email"
            name="email"
            v-model="form.email"
            placeholder="Email address"
          )
        label(
          for="name"
          :class="$v.form.name.$error && 'invalid'"
        ).form__label
          input.input(
            type="text"
            name="name"
            v-model="form.name"
            placeholder="Full Name"
          )
        label(
          for="message"
          :class="$v.form.message.$error && 'invalid'"
        ).form__label
          textarea.input(
            name="message"
            v-model="form.message"
            placeholder="Message"
          )
        div.send__block
          button(type="submit").btn.btn__send
            span Send
    div.contact__image
      img(:src="ContactImage" alt="image")
</template>

<script>
// validation
import { contactForm } from '@/configs/validationRules'
// Images
import ContactImage from '@/assets/images/contact-image.png'
export default {
  name: 'ContactForm',
  data () {
    return {
      ContactImage,
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  validations () {
    return { form: contactForm }
  },
  methods: {
    validation () {
      this.$v.form.$touch()
      return !this.$v.form.$invalid
    },
    async handleSubmit () {
      this.validation()
      try {
        if (this.validation()) {
          const response = await this.$api.post(`${ process.env.VUE_APP_API }/items/mail`, { body: { ...this.form } })
          if (response?.code === 200 || response?.code === 204) {
            if (!this.$v.$invalid) {
              for (let key in this.form) {
                this.form[key] = ''
              }
            }
            this.$v.form.$reset()
          }
        }
      } catch (error) {
        console.error('Error', error)
      }
    }
  }
}
</script>
